/**
 * 解析给定的数据并提取出所需的值
 * @param {*} data - 提供的数据对象
 * @returns {Array} - 包含提取的值的数组
 */
export function parseData(data) {
	if (data && data.data && data.data.data && (Array.isArray(data.data.data) || typeof data.data.data === "object")) {
		return Array.isArray(data.data.data) ? data.data.data : data.data;
	} else {
		return data;
	}
}


/**
 * 执行深拷贝
 * @param {*} value - 要深拷贝的值
 * @returns {*} - 深拷贝后的值
 */
export function deepCopy(value) {
	if (value === null || typeof value !== "object") {
		return value;
	}
	if (Array.isArray(value)) {
		const newArray = [];
		for (let i = 0; i < value.length; i++) {
			newArray[i] = deepCopy(value[i]);
		}
		return newArray;
	}

	if (typeof value === "object") {
		const newObj = {};
		for (let key in value) {
			if (value.hasOwnProperty(key)) {
				newObj[key] = deepCopy(value[key]);
			}
		}
		return newObj;
	}

	return value;
}

/**
 * 比较两个数组的内容是否一致，支持多维数组和包含对象的数组。
 * @param {Array} arr1 第一个数组
 * @param {Array} arr2 第二个数组
 * @returns {boolean} 如果内容一致返回 true，否则返回 false
 */
export function areArraysEqual(arr1, arr2) {
	return JSON.stringify(arr1) === JSON.stringify(arr2);
}

/**
 * 将指定文本复制到剪贴板
 * @param {string} text 要复制的文本内容
 */
export function copyToClipboard(text) {
	navigator.clipboard.writeText(text).then();
}
