<script>
export default {
	name: "Ikns",
	props: {
		title: {
			type: String,
			default: "",
		},
		children: {
			type: Document,
		},
	},
};
</script>

<template>
	<div class="i-main">
		<div class="title">
			{{ title }}
		</div>
		<div style="padding: 24rem 14rem;box-sizing: border-box">
			<slot></slot>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.i-main {
	width: calc(100% - 24rem);
	min-height: 134rem;
	background: #FFFFFF;
	border-radius: 8rem;
	padding: 0 10rem;
	box-sizing: border-box;


	.title {
		width: 100%;
		height: 52rem;
		border-bottom: 1rem solid #E6E6E6;
		font-weight: normal;
		font-size: 20rem;
		color: #1E3AA2;
		font-style: normal;
		text-transform: none;
		line-height: 52rem;
		text-indent: 14rem
	}
}
</style>